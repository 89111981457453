import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ManageProductOptions = ({ options, setOptions }) => {
  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { productOption: '', price: '', sku: '', stock: '', images: [], color: '' }]);
  };

  const handleImageUpload = (index, event) => {
    const files = Array.from(event.target.files);
    const updatedOptions = [...options];
    updatedOptions[index].images = [...updatedOptions[index].images, ...files];
    setOptions(updatedOptions);
  };

  const handleRemoveImage = (optionIndex, imageIndex) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].images = updatedOptions[optionIndex].images.filter((_, i) => i !== imageIndex);
    setOptions(updatedOptions);
  };

  const handleColorChange = (index, color) => {
    const updatedOptions = [...options];
    updatedOptions[index].color = color.hex;
    setOptions(updatedOptions);
  };

  return (
    <div className="bg-white py-5 rounded shadow mt-4">
      <h2 className="text-xl font-semibold mb-4">Manage Product Options</h2>
      <div className="flex items-center mb-4">
        <label className="mr-2">Manage Each Product</label>
        <input type="checkbox" className="toggle-checkbox" />
      </div>
      {options.map((option, index) => (
        <div key={index} className="mb-4 border-b pb-4">
          <div className="flex items-center mb-2 space-x-4">
            <input
              type="text"
              placeholder="Product Option"
              className="p-2 border rounded"
              value={option.productOption}
              onChange={(e) => handleOptionChange(index, 'productOption', e.target.value)}
            />
            <input
              type="text"
              placeholder="Price"
              className="p-2 border rounded"
              value={option.price}
              onChange={(e) => handleOptionChange(index, 'price', e.target.value)}
            />
            <input
              type="text"
              placeholder="SKU"
              className="p-2 border rounded"
              value={option.sku}
              onChange={(e) => handleOptionChange(index, 'sku', e.target.value)}
            />
            <input
              type="text"
              placeholder="Stock"
              className="p-2 border rounded"
              value={option.stock}
              onChange={(e) => handleOptionChange(index, 'stock', e.target.value)}
            />
            <SketchPicker
              color={option.color || '#ffffff'}
              onChangeComplete={(color) => handleColorChange(index, color)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Images</label>
            <div className="flex space-x-4">
              {option.images.map((image, imgIndex) => (
                <div key={imgIndex} className="relative w-24 h-24 bg-gray-200 rounded overflow-hidden">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`option-${index}-image-${imgIndex}`}
                    className="w-full h-full object-cover"
                  />
                  <button
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-bl"
                    onClick={() => handleRemoveImage(index, imgIndex)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              <label className="w-24 h-24 bg-gray-200 rounded flex items-center justify-center cursor-pointer">
                <span className="text-xl">+</span>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={(e) => handleImageUpload(index, e)}
                />
              </label>
            </div>
          </div>
        </div>
      ))}
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded"
        onClick={handleAddOption}
      >
        Add Option
      </button>
    </div>
  );
};

export default ManageProductOptions;
