import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaRegCopy } from "react-icons/fa6";

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOrderDetails = async (orderId) => {
    try {
      // First try to fetch from the first API
      const response = await axios.get(`https://api.igalaxy.co.in/api/users/order/${orderId}`);
      setOrder(response.data);
      setLoading(false);
    } catch (err) {
      // If the first API fails, try the second one
      try {
        const response = await axios.get(`https://api.igalaxy.co.in/api/users/order2/${orderId}`);
        setOrder(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  };

  
  const handleCopy = (section) => {
    let textToCopy;
    
    switch(section) {
      case 'contactInfo':
        textToCopy = `${order.shippingDetails.firstName} ${order.shippingDetails.lastName}\n${order.email} ${order.contactNumber}`;
        break;
      case 'shippingAddress':
        textToCopy = `${order.shippingDetails.firstName} ${order.shippingDetails.lastName},\n${order.shippingDetails.address},\n${order.shippingDetails.city}, ${order.shippingDetails.state} - ${order.shippingDetails.pincode}`;
        break;
      default:
        console.error("Invalid section provided");
        return;
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  useEffect(() => {
    fetchOrderDetails(id);
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!order) return <div>No order found</div>;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, pad with leading zero if necessary
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
    
    return `${year}-${month}-${day}`; // Format as "YYYY-MM-DD"
  }

  return (
    <div className="p-4 flex gap-3 font-Urbanist">
      <div className='flex-col'>
        <div className='flex text-xl font-semibold gap-3 mb-6 border-b pb-4'>
        Order #{order._id}
        <span className='font-black'>•</span>
        <h2 className="text-xl text-green-600 font-semibold">{formatDate(order.createdAt)}</h2>
        <span className='font-black'>•</span>
        <h2 className="text-xl text-green-600 font-semibold">₹{order.amount}</h2>
        </div>
      <div className="bg-white flex border rounded-xl p-6 w-[1200px] gap-4">
        <div className='w-full'>
        <div className='flex gap-8 text-md mb-5 pb-2'>
          <Link to="/orders">Orders</Link>
          Order Details
        </div>
        <div className="flex border-b pb-4 mb-4">
          <div>
            <h2 className="text-lg font-semibold">Order #{order._id}</h2>
            <p className="text-sm text-gray-500">Placed on {new Date(order.createdAt).toLocaleString()}</p>
          </div>
          <div className="text-right">
            <td className="px-2 whitespace-nowrap">
              <span
                className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${order.Payment_status === "completed"
                  ? "bg-gray-100 text-gray-800"
                  : "bg-gray-100 text-gray-800"
                  }`}
              >
                {order.Payment_status}
              </span>
            </td>
            <td className=" whitespace-nowrap">
              <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full whitespace-nowrap bg-gray-400 text-gray-800">{order.Delivery_status}</span>
            </td>
          </div>
        </div>

        <div className="p-4 border rounded-lg mb-5">
          <h3 className="text-lg border-b font-semibold mb-2">Items ({order.cart2 ? order.cart2.length : 1})</h3>
          {order.cart2 ? (
            order.cart2.map((item, index) => (
              <div key={index} className="flex justify-between items-center border-b py-2">
                <div className="flex gap-6 text-lg">
                  <img src={item.image} alt={item.name} className="w-20 h-20 object-cover" />
                  <div>
                  <p className="font-medium">{item.name}</p>
                  {item.colorName && <p className="text-sm text-gray-500">Color: {item.colorName}</p>}
                  {item.storageName && <p className="text-sm text-gray-500">Storage: {item.storageName}</p>}
                  </div>
                  <p className="font-medium text-gray-600">₹{item.discounted_price}</p>
                  {item.quantity && <p className="text-gray-500">x{item.quantity}</p>}
                  <p className="font-medium">₹{item.discounted_price}</p>
                  
                </div>
                <div>
                  
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-between items-center border-b py-2">
              <div>
                <p className="font-medium">{order.name}</p>
                {order.quantity && <p className="text-sm text-gray-500">Quantity: {order.quantity}</p>}
                {order.colorName && <p className="text-sm text-gray-500">Color: {order.colorName}</p>}
                {order.storageName && <p className="text-sm text-gray-500">Storage: {order.storageName}</p>}
              </div>
              <div className="flex items-center gap-4">
                <img src={order.image} alt={order.name} className="w-16 h-16 object-cover" />
                <p className="font-medium">₹{order.discounted_price}</p>
              </div>
            </div>
          )}
        </div>

        <div className="p-4 border rounded-lg">
          <div className='flex gap-4 border-b pb-2'>
          <p className="text-xl">Payment Info</p>
          <p className="px-2 mb-1 text-sm font-semibold rounded-full bg-gray-100 text-gray-800">{order.Payment_status}</p>
          </div>
          <div className="flex text-gray-500 justify-between items-center py-1">
            <p className=" text-gray-500">Payment Method</p>
            <p className="font-medium">{order.paymentMethod}</p>
          </div>
          <div className="flex text-gray-500 justify-between items-center py-1">
            <p className=" text-gray-500">Delivery</p>
            <p className="font-medium">Free</p>
          </div>
          <div className="flex text-gray-500 justify-between items-center py-1">
            <p className=" text-gray-500">TAX</p>
            <p className="font-medium">Included</p>
          </div>
          <div className="flex text-gray-500 justify-between items-center py-1">
            <p className=" ">Total</p>
            <p className="font-medium">₹ {order.amount}</p>
          </div>
          {/* <div className="flex justify-between items-center py-1">
            <p className="text-sm text-gray-500">Payment Status</p>
            <p className="font-medium">{order.Payment_status}</p>
          </div> */}
          <div className="flex justify-between items-center py-2 text-lg border-t mt-2 pt-2">
            <p className=" text-black">Total Amount</p>
            <p className="font-medium">₹ {order.amount}</p>
          </div>
        </div>
        </div>

        <div className='bg-white w-96 rounded-xl border divide-y'>
      <h3 className="text-lg font-semibold p-3">Order Info</h3>
      
      <div className="p-6">
        <div className='flex justify-between'>
        <p className="font-medium">Contact Info</p>
        <button onClick={() => handleCopy('contactInfo')} className="text-blue-600">
        <FaRegCopy />
        </button>
        </div>
        <p className="text-sm text-gray-500">{order.shippingDetails.firstName} {order.shippingDetails.lastName}</p>
        <p className="text-sm text-gray-500">{order.email} {order.contactNumber}</p>
      </div>

      <div className="p-6">
        <div className='flex justify-between'>
        <p className="font-medium">Shipping Address</p>
        <button onClick={() => handleCopy('shippingAddress')} className="text-blue-600">
        <FaRegCopy />
        </button>
        </div>
        <p className="text-sm text-gray-500">
          {order.shippingDetails.firstName} {order.shippingDetails.lastName},<br />
          {order.shippingDetails.address},<br />
          {order.shippingDetails.city}, {order.shippingDetails.state} - {order.shippingDetails.pincode}
        </p>
      </div>
      <div className="p-6">
        <p className="font-medium">Shipping Method</p>
        <p className="text-sm text-gray-500">{order.shippingMethod}</p>
      </div>
      <div className="p-6">
        <p className="font-medium">Delivery Status</p>
        <p className="text-sm text-gray-500">{order.Delivery_status}</p>
      </div>
    </div>
      </div>
      </div>
      
    </div>
  );
};

export default OrderDetails;
