import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CiCirclePlus } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://api.igalaxy.co.in/api/product');
        const reversedProducts = response.data.reverse(); // Reverse the order of products
        setProducts(reversedProducts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching products:', err);
        setLoading(false);
      }
    };
  
    fetchProducts();
  }, []);
  

  const handlePageChange = (e) => setPage(Number(e.target.value));
  const handleRowsPerPageChange = (e) => setRowsPerPage(Number(e.target.value));
  const filteredProducts = products.filter(product =>
    product.product_name.toLowerCase().includes(search.toLowerCase())
  );

  const handleProductClick = (id) => {
    navigate(`/edit-product/${id}`);
  };

  const handleDeleteProduct = async (id) => {
    try {
      await axios.post(`https://api.igalaxy.co.in/api/delete/${id}`);
      setProducts(products.filter(product => product._id !== id));
    } catch (err) {
      console.error('Error deleting product:', err);
    }
  };

  const getCombinationCount = (combinations) => {
    const colorStorageMap = {};
    combinations.forEach(combination => {
      const colours = Array.isArray(combination.colourcode) ? combination.colourcode : [combination.colourcode];
      const storages = Array.isArray(combination.storagecode) ? combination.storagecode : [combination.storagecode];

      colours.forEach(color => {
        storages.forEach(storage => {
          const key = `${color}-${storage}`;
          if (colorStorageMap[key]) {
            colorStorageMap[key]++;
          } else {
            colorStorageMap[key] = 1;
          }
        });
      });
    });
    return Object.keys(colorStorageMap).length;
  };

  const getImageFromAttrs2comb = (attrs2comb) => {
    return attrs2comb?.imgs?.[0]?.img_url?.[0] || '';
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg font-urbanist">
      <h1 className="text-lg border-b w-full mb-6 p-3">Products</h1>
      <div className="flex justify-between flex-row-reverse mb-4">
        <button
          className="bg-blue-800 text-white px-4 flex flex-row-reverse py-1 mb-5 rounded"
          onClick={() => navigate('/add-product')}
        >
          <CiCirclePlus className='ml-3 mt-1' />New Product
        </button>
        <input
          type="text"
          placeholder="Search products"
          className="p-3 h-10 w-2/3 border rounded"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-3 text-left text-sm font-medium border-b">No.</th>
                <th className="px-4 py-3 text-left text-sm font-medium border-b">Image</th>
                <th className="px-4 py-3 text-left text-sm font-medium border-b">Name</th>
                <th className="px-4 py-3 text-center text-sm font-medium border-b">Variants</th>
                <th className="px-3 w-8 py-3 text-center text-sm font-medium border-b">Discounted Price</th>
                <th className="px-2 w-5 text-center py-3 text-sm font-medium border-b">Price</th>
                <th className="px-4 py-3 text-left text-sm font-medium border-b">Stock</th>
                <th className="px-3 w-5 py-3 text-center text-sm font-medium border-b">Category</th>
                <th className="w-5 text-center py-3 text-sm font-medium border-b">Brand</th>
                <th className="py-3 text-left text-sm font-medium border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                <tr className=' cursor-pointer' key={index}>
                  <td className="py-2 px-4 border-b">{page * rowsPerPage + index + 1}</td>
                  <td className="py-2 px-4 border-b">
                    <img src={getImageFromAttrs2comb(product.Combination['1comb'][0].attrs2comb)} alt={product.product_name} className="w-16 h-16 object-cover" />
                  </td>
                  <td className="py-2 px-4 border-b" onClick={() => handleProductClick(product._id)}>
                    {product.product_name}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {getCombinationCount(product.Combination['1comb'])}
                  </td>
                  <td className="py-2 px-4 text-center border-b">{product.Combination['1comb'][0].discounted_price}</td>
                  <td className="py-2 px-4 text-center border-b">{product.Combination['1comb'][0].price}</td>
                  <td className="py-2 px-4 text-center border-b">{product.Stock}</td>
                  <td className="py-2 px-4 text-center border-b">{product.product_type}</td>
                  <td className="py-2 px-4 text-center border-b">{product.company_name}</td>
                  <td className="py-2 border-b">
                    <button 
                      className="text-red-600 hover:text-red-800 mr-2"
                      onClick={() => handleDeleteProduct(product._id)}
                    >
                      <AiOutlineDelete />
                    </button>
                    <button 
                      className="text-blue-600 hover:text-blue-800"
                      onClick={() => handleProductClick(product._id)}
                    >
                      <AiOutlineEdit />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="px-4 py-3 bg-gray-50 text-left mt-16 sm:px-6">
            <button
              onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
              disabled={page === 0}
              className={`px-4 py-2 bg-gray-200 text-sm font-medium rounded-md hover:bg-gray-300 ${page === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Previous
            </button>
            <span className="mx-4">Page {page + 1}</span>
            <button
              onClick={() => setPage((prevPage) => prevPage + 1)}
              disabled={page >= Math.ceil(filteredProducts.length / rowsPerPage) - 1}
              className={`px-4 py-2 bg-gray-200 text-sm font-medium rounded-md hover:bg-gray-300 ${page >= Math.ceil(filteredProducts.length / rowsPerPage) - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
