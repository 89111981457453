import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import { useParams, useNavigate } from 'react-router-dom';

const ProductUploadForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [productType, setProductType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [productName, setProductName] = useState('');
  const [keyFeatures, setKeyFeatures] = useState('');
  const [combination, setCombination] = useState([{
    colourcode: '',
    storagecode: '',
    price: 0,
    discounted_price: 0,
    attrs1comb: { imgs: '' },
    attrs2comb: { imgs: [] },
    stock: { colStorStock: [{ color: '', storage: '', stock: '' }] }
  }]);
  const [storage, setStorage] = useState([{ storage_value: '', code: '' }]);
  const [stock, setStock] = useState(0);
  const [colors, setColors] = useState([{ name: '', code: '', icon: '' }]);
  const [newAttrs2combImage, setNewAttrs2combImage] = useState({ color: '', img_file: null });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const categories = ['Mobile', 'Watch', 'Tablet', 'Laptop', 'Earphone', 'TV', 'Soundbar', 'Gaming', 'Camera', "Pre-Owned"];

  useEffect(() => {
    if (id) {
      fetchProductData(id);
    }
  }, [id]);
  const fetchProductData = async (productId) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/product/${productId}`);
      const product = response.data;

      setProductType(product.product_type);
      setCompanyName(product.company_name);
      setProductName(product.product_name);
      setKeyFeatures(product.keyFeatures.join(','));
      setCombination(product.Combination['1comb'].map(comb => ({
        ...comb,
        colourcode: comb.colourcode.join(','),
        attrs1comb: { imgs: comb.attrs1comb.imgs.join(',') },
        stock: {
          colStorStock: comb.stock?.colStorStock?.length > 0
            ? comb.stock.colStorStock.map(stockItem => ({
                color: stockItem.color || '',
                storage: stockItem.storage || '',
                stock: stockItem.stock || ''
              }))
            : [{ color: '', storage: '', stock: '' }]
        }
      })));
      
      setStorage(product.Storage.stor);
      //setStock(product.Stock);
      setColors(product.colors.col);
    } catch (err) {
      console.error('Error fetching product data:', err);
      setError('Error fetching product data: ' + err.message);
      //setLoading(false);
    }
  };



  const handleAddCombination = () => {
    setCombination([...combination, {
      colourcode: '',
      storagecode: '',
      price: 0,
      discounted_price: 0,
      attrs1comb: { imgs: '' },
      attrs2comb: { imgs: [] },
      stock: { colStorStock: [{ color: '', storage: '', stock: '' }] }
    }]);
  };

  const handleDeleteCombination = (index) => {
    const newCombination = [...combination];
    newCombination.splice(index, 1);
    setCombination(newCombination);
  };

  const handleAddStorage = () => {
    setStorage([...storage, { storage_value: '', code: '' }]);
  };

  const handleDeleteStorage = (index) => {
    const newStorage = [...storage];
    newStorage.splice(index, 1);
    setStorage(newStorage);
  };

  const handleAddColor = () => {
    setColors([...colors, { name: '', code: '', icon: '' }]);
  };

  const handleDeleteColor = (index) => {
    const newColors = [...colors];
    newColors.splice(index, 1);
    setColors(newColors);
  };

  const handleAddAttrs2combImage = async (index) => {
    if (!newAttrs2combImage.img_file) {
      alert('Please select an image file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', newAttrs2combImage.img_file);

    try {
      const fileName = newAttrs2combImage.img_file.name;
      const fileType = newAttrs2combImage.img_file.type;

      const response = await axios.post(`http://localhost:5000/s3/upload?fileName=${fileName}&fileType=${fileType}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      const imageUrl = response.data.imageUrl;
      const newCombination = [...combination];

      const existingColorIndex = newCombination[index].attrs2comb.imgs.findIndex(img => img.color === newAttrs2combImage.color);

      if (existingColorIndex !== -1) {
        newCombination[index].attrs2comb.imgs[existingColorIndex].img_url.push(imageUrl);
      } else {
        newCombination[index].attrs2comb.imgs.push({ color: newAttrs2combImage.color, img_url: [imageUrl] });
      }

      setCombination(newCombination);
      setNewAttrs2combImage({ color: '', img_file: null });
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Error uploading image: ' + err.message);
    }
  };

  const handleDeleteAttrs2combImage = (combIndex, imgIndex) => {
    const newCombination = [...combination];
    newCombination[combIndex].attrs2comb.imgs.splice(imgIndex, 1);
    setCombination(newCombination);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredCombinations = combination.map(comb => ({
      colourcode: comb.colourcode.split(','),
      price: parseFloat(comb.price),
      discounted_price: parseFloat(comb.discounted_price),
      attrs1comb: { imgs: comb.attrs1comb.imgs.split(',') },
      attrs2comb: { imgs: comb.attrs2comb.imgs.map(img => ({
        color: img.color,
        img_url: img.img_url
      })) },
      storagecode: comb.storagecode || undefined,
      stock: {
        colStorStock: comb.stock.colStorStock.map(stockItem => ({
          color: stockItem.color,
          storage: stockItem.storage,
          stock: parseInt(stockItem.stock, 10)
        }))
      }
    }));

    const data = {
      product_type: productType,
      company_name: companyName,
      product_name: productName,
      keyFeatures: keyFeatures.split(','),
      Combination: { '1comb': filteredCombinations },
      Storage: { stor: storage },
     // Stock: parseInt(stock),
      colors: { col: colors }
    };

    try {
      if (id) {
        const response = await axios.put(`http://localhost:5000/api/product2/${id}`, data);
        alert('Product updated successfully!');
      } else {
        const response = await axios.post('http://localhost:5000/api/product/upload', data);
        alert('Product uploaded successfully!');
      }
      navigate('/products'); // Redirect to product list or desired page
    } catch (err) {
      console.error('Error submitting product:', err);
      setError('Error submitting product: ' + err.message);
    }
  };

  const handleCombinationChange = (index, field, value) => {
    const newCombination = [...combination];
    if (field === 'colourcode') {
      newCombination[index].colourcode = value;
    } else if (field === 'storagecode') {
      newCombination[index].storagecode = value;
    } else if (field === 'price') {
      newCombination[index].price = value;
    } else if (field === 'discounted_price') {
      newCombination[index].discounted_price = value;
    } else if (field === 'attrs1comb.imgs') {
      newCombination[index].attrs1comb.imgs = value;
    } else if (field === 'attrs2comb.imgs') {
      // No need to parse anymore as attrs2comb.imgs is an array
    }else if (field === "stock") {
      newCombination[index].stock.colStorStock = value;
  }
    setCombination(newCombination);
  };

  const handleStorageChange = (index, field, value) => {
    const newStorage = [...storage];
    newStorage[index][field] = value;
    setStorage(newStorage);
  };


  const handleStockChange = (combIndex, stockIndex, field, value) => {
    const newCombination = [...combination];
    const updatedStock = [...newCombination[combIndex].stock.colStorStock];
    updatedStock[stockIndex][field] = value;
    newCombination[combIndex].stock.colStorStock = updatedStock;
    setCombination(newCombination);
  };

  const handleColorChange = (index, field, value) => {
    const newColors = [...colors];
    newColors[index][field] = value;
    setColors(newColors);
  };

  //   if (loading) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-4">
      <button
        className="bg-blue-600 text-white px-4 py-1 mb-5 rounded"
        onClick={() => navigate('/products')}
      >
        Back to Products
      </button>
      <h1 className="text-lg border-b w-full mb-6 p-3">{id ? 'Edit Product' : 'Upload Product'}</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-10">
          <div className="w-3/4">
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Company Name</label>
              <input
                type="text"
                className="p-3 h-10 w-full border rounded"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Product Name</label>
              <input
                type="text"
                className="p-3 h-10 w-full border rounded"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Key Features</label>
              <textarea
                className="p-3 h-20 w-full border rounded"
                value={keyFeatures}
                onChange={(e) => setKeyFeatures(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Product Type</label>
              <select
                className="p-3 h-10 w-full border rounded"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                required
              >
                <option value="">Select</option>
                {categories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            {combination.map((comb, index) => (
              <div key={index} className="mb-6 border p-4 rounded">
                <button
                  type="button"
                  className="text-red-600"
                  onClick={() => handleDeleteCombination(index)}
                >
                  Delete Combination
                </button>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Colour Code</label>
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.colourcode}
                    onChange={(e) => handleCombinationChange(index, 'colourcode', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Storage Code</label>
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.storagecode}
                    onChange={(e) => handleCombinationChange(index, 'storagecode', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Price</label>
                  <input
                    type="number"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.price}
                    onChange={(e) => handleCombinationChange(index, 'price', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Discounted Price</label>
                  <input
                    type="number"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.discounted_price}
                    onChange={(e) => handleCombinationChange(index, 'discounted_price', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-6 border p-3 rounded-md">
                  <h2 className="border-b py-3 px-3 text-l">
                    Stock*
                  </h2>
                  {comb.stock.colStorStock.map((stockItem, stockIndex) => (
                    <div key={stockIndex} className="mb-4">
                      <div className="flex gap-4">
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Color
                          </label>
                          <input
                            type="text"
                            placeholder="Red"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.color}
                            onChange={(e) =>
                              handleStockChange(index, stockIndex, "color", e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Storage
                          </label>
                          <input
                            type="text"
                            placeholder="64GB"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.storage}
                            onChange={(e) =>
                              handleStockChange(index, stockIndex, "storage", e.target.value)
                            }
                          />
                        </div>
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Stock
                          </label>
                          <input
                            type="number"
                            placeholder="10"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.stock}
                            onChange={(e) =>
                              handleStockChange(index, stockIndex, "stock", e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() =>
                      setCombination((prevComb) => {
                        const updatedComb = [...prevComb];
                        updatedComb[index].stock.colStorStock.push({
                          color: "",
                          storage: "",
                          stock: 0,
                        });
                        return updatedComb;
                      })
                    }
                  >
                    Add Stock
                  </button>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Attrs2comb Images based on color</label>

                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded mt-2"
                    value={newAttrs2combImage.color}
                    onChange={(e) => setNewAttrs2combImage({ ...newAttrs2combImage, color: e.target.value })}
                    placeholder="Enter color"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setNewAttrs2combImage({
                      ...newAttrs2combImage,
                      img_file: e.target.files[0]
                    })}
                  />
                  <button
                    type="button"
                    className="bg-green-600 text-white px-4 py-1 mt-2 rounded"
                    onClick={() => handleAddAttrs2combImage(index)}
                  >
                    Add Image
                  </button>
                </div>
                <div className="mb-4">
  <label className="block text-gray-700 mb-2">Images</label>
  {comb.attrs2comb.imgs.map((img, combIndex) => (
    <div key={combIndex} className="mb-4">
      <h4 className="text-gray-800 mb-2">{`Color: ${img.color}`}</h4>
      {img.img_url.map((url, imgIndex) => (
        <div key={imgIndex} className="relative flex items-center mb-2">
          <img
            src={url}
            alt={`Combination Image ${imgIndex}`}
            className="w-20 h-20 object-cover mr-2 cursor-pointer"
            onClick={() => handleDeleteAttrs2combImage(combIndex, imgIndex)}
          />
          <button
            type="button"
            className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-full"
            onClick={(e) => {
              e.stopPropagation(); // Prevents click event from firing on the image
              handleDeleteAttrs2combImage(combIndex, imgIndex);
            }}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  ))}
</div>

              </div>
            ))}
            <button
              type="button"
              className="bg-green-600 text-white px-4 py-2 rounded mb-6"
              onClick={handleAddCombination}
            >
              Add Combination
            </button>
          </div>
          <div className="w-1/4">
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Storage</label>
              {storage.map((stor, index) => (
                <div key={index} className="flex items-center mb-4">
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    placeholder="Storage Value"
                    value={stor.storage_value}
                    onChange={(e) => handleStorageChange(index, 'storage_value', e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded ml-2"
                    placeholder="Code"
                    value={stor.code}
                    onChange={(e) => handleStorageChange(index, 'code', e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="text-red-600 ml-2"
                    onClick={() => handleDeleteStorage(index)}
                  >
                    Delete Storage
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="bg-green-600 text-white px-4 py-2 rounded"
                onClick={handleAddStorage}
              >
                Add Storage
              </button>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Colors</label>
              {colors.map((color, index) => (
                <div key={index} className="flex items-center mb-4">
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    placeholder="Color Name"
                    value={color.name}
                    onChange={(e) => handleColorChange(index, 'name', e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded ml-2"
                    placeholder="Color Code"
                    value={color.code}
                    onChange={(e) => handleColorChange(index, 'code', e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded ml-2"
                    placeholder="Icon URL"
                    value={color.icon}
                    onChange={(e) => handleColorChange(index, 'icon', e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="text-red-600 ml-2"
                    onClick={() => handleDeleteColor(index)}
                  >
                    Delete Color
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="bg-green-600 text-white px-4 py-2 rounded"
                onClick={handleAddColor}
              >
                Add Color
              </button>
            </div>
            {/* <div className="mb-6">
              <label className="block text-gray-700 mb-2">Stock</label>
              <input
                type="number"
                className="p-3 h-10 w-full border rounded"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                required
              />
            </div> */}
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded"
          >
            {id ? 'Update Product' : 'Upload Product'}
          </button>
        </div>
        {error && <p className="text-red-600 mt-4">{error}</p>}
      </form>
    </div>
  );
};

export default ProductUploadForm;