import React, { useState } from "react";
import axios from "axios";
import "tailwindcss/tailwind.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductUploadForm = () => {
  const [productType, setProductType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productName, setProductName] = useState("");
  const [keyFeatures, setKeyFeatures] = useState("");
  const [combination, setCombination] = useState([
    {
      colourcode: "",
      storagecode: "",
      price: 0,
      discounted_price: 0,
      attrs1comb: { imgs: "" },
      attrs2comb: { imgs: [] },
      stock: { colStorStock: [{ color: "", storage: "", stock: "" }] },
    },
  ]);
  const [storage, setStorage] = useState([{ storage_value: "", code: "" }]);
  const [stock, setStock] = useState({ color: "", storage: "", stock: 0 });
  const [colors, setColors] = useState([{ name: "", code: "", icon: "" }]);
  const [newAttrs2combImage, setNewAttrs2combImage] = useState({
    color: "",
    img_file: null,
  });
  const [error, setError] = useState("");

  const categories = [
    "Mobile",
    "Watch",
    "Tablet",
    "Laptop",
    "Earphone",
    "TV",
    "Soundbar",
    "Gaming",
    "Camera",
    "Pre-Owned",
  ];

  const handleAddCombination = () => {
    setCombination([
      ...combination,
      {
        colourcode: "",
        storagecode: "",
        price: 0,
        discounted_price: 0,
        attrs1comb: { imgs: "" },
        attrs2comb: { imgs: [] },
        stock: { colStorStock: [{ color: "", storage: "", stock: "" }] },
      },
    ]);
  };

  const handleDeleteCombination = (index) => {
    const newCombination = [...combination];
    newCombination.splice(index, 1);
    setCombination(newCombination);
  };

  const handleAddStorage = () => {
    setStorage([...storage, { storage_value: "", code: "" }]);
  };

  const handleDeleteStorage = (index) => {
    const newStorage = [...storage];
    newStorage.splice(index, 1);
    setStorage(newStorage);
  };

  // const addStock = () => {
  //   setCombination({
  //     ...combination,
  //     Stock: {
  //       colStorStock: [...combination.Stock.colStorStock, stock]
  //     }
  //   });
  //   setStock({ color: '', storage: '', stock: 0 });
  // };

  const handleAddColor = () => {
    setColors([...colors, { name: "", code: "", icon: "" }]);
  };

  const handleDeleteColor = (index) => {
    const newColors = [...colors];
    newColors.splice(index, 1);
    setColors(newColors);
  };

  const handleAddAttrs2combImage = async (index) => {
    if (!newAttrs2combImage.img_file) {
      alert("Please select an image file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", newAttrs2combImage.img_file);

    try {
      // Extract file details
      const fileName = newAttrs2combImage.img_file.name;
      const fileType = newAttrs2combImage.img_file.type;

      const response = await axios.post(
        `https://api.igalaxy.co.in/s3/upload?fileName=${fileName}&fileType=${fileType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const imageUrl = response.data.imageUrl;
      const newCombination = [...combination];

      const existingColorIndex = newCombination[
        index
      ].attrs2comb.imgs.findIndex(
        (img) => img.color === newAttrs2combImage.color
      );

      if (existingColorIndex !== -1) {
        // If color code exists, add the new image URL to the existing entry
        newCombination[index].attrs2comb.imgs[existingColorIndex].img_url.push(
          imageUrl
        );
      } else {
        // If color code does not exist, create a new entry with the color and image URL
        newCombination[index].attrs2comb.imgs.push({
          color: newAttrs2combImage.color,
          img_url: [imageUrl],
        });
      }

      setCombination(newCombination);
      setNewAttrs2combImage({ color: "", img_file: null }); // Reset new image state
    } catch (err) {
      console.error("Error uploading image:", err);
      setError("Error uploading image: " + err.message);
    }
  };

  const handleDeleteAttrs2combImage = (combIndex, imgIndex) => {
    const newCombination = [...combination];
    newCombination[combIndex].attrs2comb.imgs.splice(imgIndex, 1);
    setCombination(newCombination);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.success("Image added successfully!");

    // Filter out combinations without storage code
    const filteredCombinations = combination.map((comb) => ({
      colourcode: comb.colourcode.split(","),
      storagecode: comb.storagecode || "",
      price: parseFloat(comb.price),
      discounted_price: parseFloat(comb.discounted_price),
      attrs1comb: {
        imgs: comb.attrs1comb.imgs,
      },
      attrs2comb: {
        imgs: comb.attrs2comb.imgs.map((img) => ({
          color: img.color,
          img_url: img.img_url,
        })),
      },
      stock: {
        colStorStock: comb.stock.colStorStock.map((stockItem) => ({
          color: stockItem.color,
          storage: stockItem.storage,
          stock: parseInt(stockItem.stock, 10),
        })),
      },
    }));

    const data = {
      product_type: productType,
      company_name: companyName,
      product_name: productName,
      keyFeatures: keyFeatures.split(","),
      Combination: { "1comb": filteredCombinations },
      Storage: storage.length > 0 ? { stor: storage } : { stor: [] },
      //Stock: parseInt(stock),
      colors: { col: colors },
    };

    console.log("Submitting data:", JSON.stringify(data, null, 2));

    try {
      const response = await axios.post(
        "https://api.igalaxy.co.in/api/product/upload",
        data
      );
      console.log("Response from server:", response.data);
      alert("Product uploaded successfully!");
    } catch (err) {
      console.error("Error uploading product:", err);
      setError("Error uploading product: " + err.message);
    }
  };

  const handleCombinationChange = (index, field, value) => {
    const newCombination = [...combination];
    if (field === "colourcode") {
      newCombination[index].colourcode = value;
    } else if (field === "storagecode") {
      newCombination[index].storagecode = value;
    } else if (field === "price") {
      newCombination[index].price = value;
    } else if (field === "discounted_price") {
      newCombination[index].discounted_price = value;
    } else if (field === "stock") {
      newCombination[index].stock.colStorStock = value;
    }
    setCombination(newCombination);
  };

  const handleStockChange = (combIndex, stockIndex, field, value) => {
    const newCombination = [...combination];
    const updatedStock = [...newCombination[combIndex].stock.colStorStock];
    updatedStock[stockIndex][field] = value;
    newCombination[combIndex].stock.colStorStock = updatedStock;
    setCombination(newCombination);
  };

  const handleStorageChange = (index, field, value) => {
    const newStorage = [...storage];
    newStorage[index][field] = value;
    setStorage(newStorage);
  };

  const handleColorChange = (index, field, value) => {
    const newColors = [...colors];
    newColors[index][field] = value;
    setColors(newColors);
  };

  return (
    <div className="container mx-auto p-4">
      <button
        className="bg-gray-800 text-white px-4 py-1 mb-5 rounded"
        onClick={() => window.history.back()}
      >
        Back to Products
      </button>
      <h1 className="text-lg border-b w-full mb-6 p-3">Upload Product</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-10">
          <div className="w-3/4">
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Company Name *
              </label>
              <input
                type="text"
                placeholder="OPPO"
                className="p-3 h-10 w-full border rounded"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Product Name *
              </label>
              <input
                type="text"
                placeholder="OPPO Reno 11 5g"
                className="p-3 h-10 w-full border rounded"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-medium mb-2">
                Key Features (comma separated)*
              </label>
              <input
                type="text"
                placeholder="50MP Triple Rear Camera, 32 MP Front Selfie Camera, 8GB RAM | 128GB ROM, 6.7 inch AMOLED Display "
                className="p-3 h-10 w-full border rounded"
                value={keyFeatures}
                onChange={(e) => setKeyFeatures(e.target.value)}
                required
              />
            </div>
            {combination.map((comb, index) => (
              <div key={index} className="mb-6 border p-3 rounded-md">
                <h2 className="border-b py-4 px-8 text-xl">
                  Combination {index + 1}
                </h2>
                <div className="p-6">
                  <label className="block text-gray-800 font-medium mb-2">
                    All Colour Code (Comma separated) *
                  </label>
                  <input
                    type="text"
                    placeholder="#2A8570,#2A8570"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.colourcode}
                    onChange={(e) =>
                      handleCombinationChange(
                        index,
                        "colourcode",
                        e.target.value
                      )
                    }
                    required
                  />
                </div>
                <div className="p-6">
                  <label className="block text-gray-800 font-medium mb-2">
                    Storage Code {index + 1}*
                  </label>
                  <input
                    type="text"
                    placeholder="code u will asigin in storage code 1"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.storagecode}
                    onChange={(e) =>
                      handleCombinationChange(
                        index,
                        "storagecode",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="p-6">
                  <label className="block text-gray-800 font-medium mb-2">
                    Price (Original Price 24999) *
                  </label>
                  <input
                    type="number"
                    placeholder="24999"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.price}
                    onChange={(e) =>
                      handleCombinationChange(index, "price", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="p-6">
                  <label className="block text-gray-800 font-medium mb-2">
                    Discounted Price (Price after discount 19999) *
                  </label>
                  <input
                    type="number"
                    placeholder="19999"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.discounted_price}
                    onChange={(e) =>
                      handleCombinationChange(
                        index,
                        "discounted_price",
                        e.target.value
                      )
                    }
                    required
                  />
                </div>
                <div className="mb-6 border p-3 rounded-md">
                  <h2 className="border-b py-3 px-3 text-l">Stock*</h2>
                  {comb.stock.colStorStock.map((stockItem, stockIndex) => (
                    <div key={stockIndex} className="mb-4">
                      <div className="flex gap-4">
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Color code {stockIndex + 1}*
                          </label>
                          <input
                            type="text"
                            placeholder="Red"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.color}
                            onChange={(e) =>
                              handleStockChange(
                                index,
                                stockIndex,
                                "color",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Storage Code (if given) {stockIndex + 1}*
                          </label>
                          <input
                            type="text"
                            placeholder="64GB"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.storage}
                            onChange={(e) =>
                              handleStockChange(
                                index,
                                stockIndex,
                                "storage",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="w-1/3">
                          <label className="block text-gray-800 font-medium mb-2">
                            Stock Value {stockIndex + 1}*
                          </label>
                          <input
                            type="number"
                            placeholder="10"
                            className="p-3 h-10 w-full border rounded"
                            value={stockItem.stock}
                            onChange={(e) =>
                              handleStockChange(
                                index,
                                stockIndex,
                                "stock",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  
                  <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => {
                      setCombination((prevComb) => {
                        // Make sure to create a new array to avoid mutating the state directly
                        const updatedComb = prevComb.map((comb, i) => {
                          if (i === index) {
                            // Create a new stock entry
                            return {
                              ...comb,
                              stock: {
                                ...comb.stock,
                                colStorStock: [
                                  ...comb.stock.colStorStock,
                                  { color: "", storage: "", stock: 0 },
                                ],
                              },
                            };
                          }
                          return comb;
                        });
                        return updatedComb;
                      });
                    }}
                  >
                    Add Stock
                  </button>
                </div>
                {/* <div className="p-6">
                  <label className="block text-gray-700 mb-2">Attrs1comb Images (comma separated URLs)</label>
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    value={comb.attrs1comb.imgs}
                    onChange={(e) => handleCombinationChange(index, 'attrs1comb.imgs', e.target.value)}
                  />
                </div> */}
                <div className="p-6">
                  <label className="block text-gray-700 font-medium mb-2">
                    Combination of color image 1
                  </label>
                  <input
                    type="file"
                    className="p-3 h-10 w-full border rounded"
                    onChange={(e) =>
                      setNewAttrs2combImage({
                        ...newAttrs2combImage,
                        img_file: e.target.files[0],
                      })
                    }
                  />
                  <label className="block text-gray-700 font-medium">
                    Images to be uploaded for Second color code use comma after
                    it{" "}
                  </label>
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded mt-2"
                    value={newAttrs2combImage.color}
                    onChange={(e) =>
                      setNewAttrs2combImage({
                        ...newAttrs2combImage,
                        color: e.target.value,
                      })
                    }
                    placeholder="Enter color code (for ex:#2A8570, #2A8570, #2A8570)"
                  />
                  <button
                    type="button"
                    onClick={() => handleAddAttrs2combImage(index)}
                    className="mt-2 bg-blue-700 text-white px-4 py-1 rounded"
                  >
                    Add Image
                  </button>
                </div>
                {comb.attrs2comb.imgs.map((img, imgIndex) => (
                  <div key={imgIndex} className="flex items-center mt-2">
                    <span className="text-gray-700">{img.color}:</span>
                    <a
                      href={img.img_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-2 text-blue-600 underline"
                    >
                      {img.img_url}
                    </a>
                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteAttrs2combImage(index, imgIndex)
                      }
                      className="ml-2 text-red-600"
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => handleDeleteCombination(index)}
                  className="mt-2 bg-red-700 text-white px-4 py-1 rounded"
                >
                  Delete Combination
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddCombination}
              className="mt-4 bg-green-700 text-white px-4 py-2 rounded"
            >
              Add Combination
            </button>
          </div>
          <div className="w-1/4">
            <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2">
                Product Type *
              </label>
              <select
                className="p-3 h-12 w-full border rounded"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                required
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2">
                Stock (No. of product stock) *
              </label>
              <input
                type="number"
                className="p-3 h-10 w-full border rounded"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                required
              />
            </div> */}
            <div className="mb-6 border p-3 rounded-md">
              <h2 className="border-b py-3 px-3 text-l">
                Storage (Add storage if needed) *
              </h2>
              {storage.map((stor, index) => (
                <div key={index} className="mb-6">
                  <label className="block text-gray-800 mb-2">
                    Storage Value {index + 1}*
                  </label>
                  <input
                    type="text"
                    placeholder="8Gb+128"
                    className="p-3 h-10 w-full border rounded"
                    value={stor.storage_value}
                    onChange={(e) =>
                      handleStorageChange(
                        index,
                        "storage_value",
                        e.target.value
                      )
                    }
                    required
                  />
                  <label className="block text-gray-700 mb-2 mt-2">
                    Storage Code {index + 1}*
                  </label>
                  <input
                    type="text"
                    placeholder="Assigin any value"
                    className="p-3 h-10 w-full border rounded"
                    value={stor.code}
                    onChange={(e) =>
                      handleStorageChange(index, "code", e.target.value)
                    }
                    required
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteStorage(index)}
                    className="mt-2 bg-red-700 text-white px-4 py-1 rounded"
                  >
                    Delete Storage
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddStorage}
                className="mt-4 bg-green-700 text-white px-4 py-2 rounded"
              >
                Add Storage
              </button>
            </div>
            <div className="mb-6 border p-3 rounded-md">
              <h2 className="border-b py-3 px-3 text-l">Colors *</h2>
              {colors.map((color, index) => (
                <div key={index} className="mb-6">
                  <label className="block text-gray-700 mb-2">
                    Color Name {index + 1}*
                  </label>
                  <input
                    type="text"
                    placeholder="Wave Green"
                    className="p-3 h-10 w-full border rounded"
                    value={color.name}
                    onChange={(e) =>
                      handleColorChange(index, "name", e.target.value)
                    }
                    required
                  />
                  <label className="block text-gray-700 mb-2 mt-2">
                    Color Code {index + 1} (#Hash-Value)*{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="#2A8570"
                    className="p-3 h-10 w-full border rounded"
                    value={color.code}
                    onChange={(e) =>
                      handleColorChange(index, "code", e.target.value)
                    }
                    required
                  />
                  {/* <label className="block text-gray-700 mb-2 mt-2">Color Icon (URL)</label>
                  <input
                    type="text"
                    className="p-3 h-10 w-full border rounded"
                    value={color.icon}
                    onChange={(e) => handleColorChange(index, 'icon', e.target.value)}
                    required
                  /> */}
                  <button
                    type="button"
                    onClick={() => handleDeleteColor(index)}
                    className="mt-2 bg-red-700 text-white px-4 py-1 rounded"
                  >
                    Delete Color
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddColor}
                className="mt-4 bg-green-700 text-white px-4 py-2 rounded"
              >
                Add Color
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-6">
          <button
            type="submit"
            className="bg-blue-700 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </div>
        {error && <div className="text-red-700 mt-4">{error}</div>}
      </form>
      <ToastContainer />
    </div>
  );
};

export default ProductUploadForm;
