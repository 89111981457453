import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ProductPage from "./components/ProductPage";
import AddProductPage from "./components/AddProductPage";
import OrderDetails from "./components/Orderdetails";
import Orders from "./components/Orders";
import ProductUploadForm from "./components/addprod";
import Ord from "./components/kjhh";
import Editproduct from "./components/editProduct";
import LoginPage from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";

export default function App() {
  const [products, setProducts] = useState([]);

  const addProduct = (newProduct) => {
    setProducts([...products, newProduct]);
  };

  const updateProduct = (updatedProduct, index) => {
    const newProducts = [...products];
    newProducts[index] = updatedProduct;
    setProducts(newProducts);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Routes>
                  <Route path="/edit-product/:id" element={<Editproduct />} />
                  <Route path="/ord" element={<Ord />} />
                  <Route path="/" element={<Orders />} />
                  <Route path="orders" element={<Orders />} />
                  <Route path="/orderdetails/:id" element={<OrderDetails />} />
                  <Route path="/products" element={<ProductPage products={products} />} />
                  <Route path="/add-product" element={<AddProductPage addProduct={addProduct} />} />
                  <Route path="/add" element={<ProductUploadForm addProduct={ProductUploadForm} />} />
                  <Route path="/edit-product/:index" element={<AddProductPage products={products} updateProduct={updateProduct} />} />
                </Routes>
              </Sidebar>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
