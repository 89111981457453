import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from 'react-spinners'; // Import the spinner

const OrderPage = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const [cartOrdersResponse, buyNowOrdersResponse] = await Promise.all([
          axios.get('https://api.igalaxy.co.in/api/users/getorderall'),
          axios.get('https://api.igalaxy.co.in/api/users/getbuyallorder')
        ]);

        const combinedOrders = [
          ...cartOrdersResponse.data.orders.map(order => ({ ...order, orderType: 'cartOrder' })),
          ...buyNowOrdersResponse.data.buyNowOrders.map(order => ({ ...order, orderType: 'buyNowOrder' }))
        ];

        // Sort orders by date (newest first)
        combinedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const ordersWithRequiredFields = combinedOrders.map(order => ({
          id: order._id,
          createdAt: order.createdAt,
          firstname: order.shippingDetails.firstName || 'N/A',
          lastName: order.shippingDetails.lastName || 'N/A',
          subtotal: order.amount,
          paymentStatus: order.Payment_status,
          items: order.cart2 ? order.cart2.length : 1,
          deliveryStatus: order.Delivery_status,
          orderType: order.orderType
        }));

        setOrders(ordersWithRequiredFields);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
        setError('Error fetching orders');
      }
    };

    fetchOrders();
  }, [navigate]);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handlePageChange = (newPage) => setPage(newPage);
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0); // Reset to first page
  };
  const handleRowClick = (orderId) => navigate(`/orderdetails/${orderId}`);

  const handleDeliveryStatusChange = async (e, orderId, orderType) => {
    e.stopPropagation();
    const newStatus = e.target.value;
    const endpoint = orderType === 'cartOrder' ? `https://api.igalaxy.co.in/api/users/${orderId}/status` : `https://api.igalaxy.co.in/api/users/${orderId}/status2`;

    try {
      await axios.put(endpoint, { newStatus });
      setOrders(orders.map(order => order.id === orderId ? { ...order, deliveryStatus: newStatus } : order));
    } catch (error) {
      console.error('Error updating delivery status:', error);
      setError('Error updating delivery status');
    }
  };

  const filteredOrders = orders.filter(order => {
    const matchesSearchTerm = order.id.includes(searchTerm) ||
                             order.firstname.toLowerCase().includes(searchTerm.toLowerCase());

    const orderDate = new Date(order.createdAt);
    const startDateObj = startDate ? new Date(startDate) : null;
    const endDateObj = endDate ? new Date(endDate) : null;

    const matchesDateRange = (!startDate && !endDate) ||
                             (startDateObj && !endDateObj && orderDate >= startDateObj) ||
                             (!startDateObj && endDateObj && orderDate <= endDateObj) ||
                             (startDateObj && endDateObj && orderDate >= startDateObj && orderDate <= endDateObj);

    return matchesSearchTerm && matchesDateRange;
  });

  const totalRevenue = filteredOrders.reduce((acc, order) => acc + order.subtotal, 0);
  const orderCount = filteredOrders.length;

  const totalPages = Math.ceil(filteredOrders.length / rowsPerPage);

  return (
    <div className="max-w-9xl mx-auto p-4">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-2xl border-b py-4 text-green-600 font-semibold">
                Orders • {orderCount} Orders • ₹{totalRevenue} Revenue
              </div>
            </div>
            <div className="flex justify-between items-center mb-14">
              <div className="flex space-x-2 items-center">
                <input
                  type="text"
                  placeholder="Search order"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="border border-gray-300 rounded-md p-2"
                />
                <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText='Enter the start date'
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="border border-gray-300 rounded-md p-2 ml-2"
                />
                <span className="mx-2">to</span>
                <DatePicker
                  id="endDate"
                  selected={endDate}
                  placeholderText='Enter the end date'
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">No.</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Order</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Date</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Customer</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Subtotal</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Payment Status</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Items</th>
                  <th scope="col" className="px-4 py-3 text-left text-sm font-medium text-gray-500">Delivery Status</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
                  <tr key={order.id} onClick={() => handleRowClick(order.id)} className="cursor-pointer hover:bg-slate-50">
                    <td className="px-6 py-2 whitespace-nowrap">{index + 1 + page * rowsPerPage}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{order.id}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{order.firstname} {order.lastName}</td>
                    <td className="px-6 py-2 whitespace-nowrap">₹{order.subtotal}</td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          order.paymentStatus === "completed"
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {order.paymentStatus}
                      </span>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">{order.items}</td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <select
                        value={order.deliveryStatus}
                        onChange={(e) => handleDeliveryStatusChange(e, order.id, order.orderType)}
                        className="border border-gray-300 rounded-md p-1"
                      >
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 0}
                className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
              >
                Previous
              </button>
              <span>
                Page {page + 1} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= totalPages - 1}
                className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
              >
                Next
              </button>
              <select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="border border-gray-300 rounded-md p-2"
              >
                <option value={5}>5 rows per page</option>
                <option value={10}>10 rows per page</option>
                <option value={20}>20 rows per page</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderPage;
