import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaClipboardList, FaBoxOpen, FaTimes, FaSignOutAlt } from "react-icons/fa";
import AuthService from './AuthService'; // Import AuthService

export default function Sidebar({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthService.logout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col min-h-screen font-Urbanist">
      <div className="flex min-h-screen">
        <div className={`side-bar sticky gap-5 sm:relative border-r bg-gray-100 transform ${isOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 sm:translate-x-0`}>
          <button
            className="sm:hidden absolute top-4 right-4"
            onClick={() => setIsOpen(false)}
          >
            <FaTimes />
          </button>
          <div className="logo">
            <img src="/images/logo.svg" alt="I-Galaxy" className="h-full p-6 pl-4 mx-6"/>
          </div>
          
          <NavLink to="/orders" className={({ isActive }) => `p-2 h-11 menu-item flex gap-3 px-6 cursor-pointer transition duration-300 ${isActive ? "bg-black text-white" : "text-black hover:bg-black hover:text-white"}`}>
            <FaClipboardList className="mt-1" />
            Orders 
          </NavLink>
          
          <NavLink to="/products" className={({ isActive }) => `p-2 h-11 menu-item flex gap-3 px-6 cursor-pointer transition duration-300 ${isActive ? "bg-black text-white" : "text-black hover:bg-black hover:text-white"}`}>
            <FaBoxOpen className="mt-1" />
            Products
          </NavLink>
          
          {/* Logout Button */}
          <button
            className="p-2 h-11 menu-item w-full flex gap-3 px-6 cursor-pointer transition duration-300 text-black hover:bg-black hover:text-white mt-auto"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mt-1" />
            Logout
          </button>
        </div>
        <div className="flex-1 p-4 bg-slate-100">
          {children}
        </div>
      </div>
    </div>
  );
}
