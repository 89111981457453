// import React, { useState } from 'react';
// import axios from 'axios';

// const ProductUploadForm = () => {
//   const [productType, setProductType] = useState('');
//   const [companyName, setCompanyName] = useState('');
//   const [productName, setProductName] = useState('');
//   const [keyFeatures, setKeyFeatures] = useState('');
//   const [combination, setCombination] = useState([{ colourcode: [], price: 0, discounted_price: 0, attrs1comb: { imgs: [] }, attrs2comb: { imgs: [] } }]);
//   const [storage, setStorage] = useState([{ storage_value: '', code: '' }]);
//   const [stock, setStock] = useState(0);
//   const [colors, setColors] = useState([{ name: '', code: '', icon: '' }]);
//   const [error, setError] = useState('');

//   const handleAddCombination = () => {
//     setCombination([...combination, { colourcode: [], price: 0, discounted_price: 0, attrs1comb: { imgs: [] }, attrs2comb: { imgs: [] } }]);
//   };

//   const handleAddStorage = () => {
//     setStorage([...storage, { storage_value: '', code: '' }]);
//   };

//   const handleAddColor = () => {
//     setColors([...colors, { name: '', code: '', icon: '' }]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = {
//       product_type: productType,
//       company_name: companyName,
//       product_name: productName,
//       keyFeatures: keyFeatures.split(','),
//       Combination: { '1comb': combination },
//       Storage: { stor: storage },
//       Stock: stock,
//       colors: { col: colors }
//     };

//     try {
//       await axios.post('YOUR_BACKEND_API_URL', data);
//       alert('Product uploaded successfully!');
//     } catch (err) {
//       setError('Error uploading product: ' + err.message);
//     }
//   };

//   return (
//     <div>
//       <h1>Upload Product</h1>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Product Type:</label>
//           <input type="text" value={productType} onChange={(e) => setProductType(e.target.value)} required />
//         </div>
//         <div>
//           <label>Company Name:</label>
//           <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
//         </div>
//         <div>
//           <label>Product Name:</label>
//           <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} required />
//         </div>
//         <div>
//           <label>Key Features (comma separated):</label>
//           <input type="text" value={keyFeatures} onChange={(e) => setKeyFeatures(e.target.value)} required />
//         </div>

//         <div>
//           <h3>Combination</h3>
//           {combination.map((comb, index) => (
//             <div key={index}>
//               <label>Colour Code (comma separated):</label>
//               <input type="text" value={comb.colourcode} onChange={(e) => {
//                 const newCombination = [...combination];
//                 newCombination[index].colourcode = e.target.value.split(',');
//                 setCombination(newCombination);
//               }} required />
//               <label>Price:</label>
//               <input type="number" value={comb.price} onChange={(e) => {
//                 const newCombination = [...combination];
//                 newCombination[index].price = e.target.value;
//                 setCombination(newCombination);
//               }} required />
//               <label>Discounted Price:</label>
//               <input type="number" value={comb.discounted_price} onChange={(e) => {
//                 const newCombination = [...combination];
//                 newCombination[index].discounted_price = e.target.value;
//                 setCombination(newCombination);
//               }} required />
//               <label>Attrs1Comb Images (comma separated URLs):</label>
//               <input type="text" value={comb.attrs1comb.imgs} onChange={(e) => {
//                 const newCombination = [...combination];
//                 newCombination[index].attrs1comb.imgs = e.target.value.split(',');
//                 setCombination(newCombination);
//               }} required />
//               <label>Attrs2Comb Images (comma separated with color):</label>
//               <input type="text" value={comb.attrs2comb.imgs.map(i => `${i.color}:${i.img_url.join(',')}`).join(';')} onChange={(e) => {
//                 const newCombination = [...combination];
//                 newCombination[index].attrs2comb.imgs = e.target.value.split(';').map(i => {
//                   const [color, ...img_urls] = i.split(':');
//                   return { color, img_url: img_urls.join(':').split(',') };
//                 });
//                 setCombination(newCombination);
//               }} required />
//             </div>
//           ))}
//           <button type="button" onClick={handleAddCombination}>Add Combination</button>
//         </div>

//         <div>
//           <h3>Storage</h3>
//           {storage.map((stor, index) => (
//             <div key={index}>
//               <label>Storage Value:</label>
//               <input type="text" value={stor.storage_value} onChange={(e) => {
//                 const newStorage = [...storage];
//                 newStorage[index].storage_value = e.target.value;
//                 setStorage(newStorage);
//               }} />
//               <label>Code:</label>
//               <input type="text" value={stor.code} onChange={(e) => {
//                 const newStorage = [...storage];
//                 newStorage[index].code = e.target.value;
//                 setStorage(newStorage);
//               }} />
//             </div>
//           ))}
//           <button type="button" onClick={handleAddStorage}>Add Storage</button>
//         </div>

//         <div>
//           <label>Stock:</label>
//           <input type="number" value={stock} onChange={(e) => setStock(e.target.value)} required />
//         </div>

//         <div>
//           <h3>Colors</h3>
//           {colors.map((color, index) => (
//             <div key={index}>
//               <label>Color Name:</label>
//               <input type="text" value={color.name} onChange={(e) => {
//                 const newColors = [...colors];
//                 newColors[index].name = e.target.value;
//                 setColors(newColors);
//               }} required />
//               <label>Color Code:</label>
//               <input type="text" value={color.code} onChange={(e) => {
//                 const newColors = [...colors];
//                 newColors[index].code = e.target.value;
//                 setColors(newColors);
//               }} required />
//               <label>Color Icon:</label>
//               <input type="text" value={color.icon} onChange={(e) => {
//                 const newColors = [...colors];
//                 newColors[index].icon = e.target.value;
//                 setColors(newColors);
//               }} required />
//             </div>
//           ))}
//           <button type="button" onClick={handleAddColor}>Add Color</button>
//         </div>

//         <button type="submit">Upload Product</button>
//       </form>
//       {error && <p>{error}</p>}
//     </div>
//   );
// };

// export default ProductUploadForm;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ManageProductOptions from './ManageProductOptions';
import 'tailwindcss/tailwind.css';

const AddProductPage = ({ products, addProduct, updateProduct }) => {
  const { index } = useParams();
  const navigate = useNavigate();

  const isEdit = index !== undefined;
  const initialProduct = isEdit ? products[index] : {
    name: '',
    price: '',
    discountedPrice: '',
    description: '',
    brand: '',
    stock: 0,
    categories: [],
    images: [],
    options: []
  };

  const [productName, setProductName] = useState(initialProduct.name);
  const [productPrice, setProductPrice] = useState(initialProduct.price);
  const [discountedPrice, setDiscountedPrice] = useState(initialProduct.discountedPrice);
  const [description, setDescription] = useState(initialProduct.description);
  const [selectedCategories, setSelectedCategories] = useState(initialProduct.categories);
  const [images, setImages] = useState(initialProduct.images);
  const [options, setOptions] = useState(initialProduct.options);
  const [brand, setBrand] = useState(initialProduct.brand);
  const [stock, setStock] = useState(initialProduct.stock);
  const [onSale, setOnSale] = useState(false);
  const [discountType, setDiscountType] = useState('%');
  const [discountValue, setDiscountValue] = useState('');

  const categories = [
    'Smartphones', 'Watches', 'Tablets', 'Laptops', 'Earphones', 'TVs', 'Soundbars', 'Gaming', 'Cameras'
  ];

  useEffect(() => {
    if (isEdit) {
      setProductName(initialProduct.name);
      setProductPrice(initialProduct.price);
      setDiscountedPrice(initialProduct.discountedPrice);
      setDescription(initialProduct.description);
      setSelectedCategories(initialProduct.categories);
      setImages(initialProduct.images);
      setOptions(initialProduct.options);
      setBrand(initialProduct.brand);
      setStock(initialProduct.stock);
      setOnSale(initialProduct.discountedPrice < initialProduct.price);
    }
  }, [isEdit, initialProduct]);

  const handleAddProduct = (e) => {
    e.preventDefault();

    if (!productName || !productPrice || !discountedPrice || !description || !selectedCategories.length || !brand || !stock) {
      alert('All fields are mandatory');
      return;
    }

    const newProduct = {
      name: productName,
      price: productPrice,
      discountedPrice,
      description,
      categories: selectedCategories,
      images,
      options,
      brand,
      stock,
    };

    if (isEdit) {
      updateProduct(newProduct, index);
    } else {
      addProduct(newProduct);
    }

    navigate('/');
  };

  return (
    <div className="container mx-auto p-4">
      <button
        className="bg-blue-600 text-white px-4 py-1 mb-5 rounded"
        onClick={() => navigate('/')}
      >
        Back to Products
      </button>
      <h1 className="text-lg border-b w-full mb-6 p-3">{isEdit ? 'Edit Product' : 'Add New Product'}</h1>
      <form onSubmit={handleAddProduct}>
        <div className="grid grid-cols-3 gap-10">
          <div className=' col-span-2'>
            <div className="mb-6 border p-3 rounded-md">
              <label className="block text-gray-700 mb-2 border-b py-3">Images & Videos</label>
              <div className="grid grid-cols-5 gap-1">
                {images.map((image, idx) => (
                  <img key={idx} src={URL.createObjectURL(image)} alt="product" className="h-32 w-32 object-cover" />
                ))}
                <label className="flex items-center justify-center border-2 border-dashed border-gray-300 h-32 w-32 cursor-pointer">
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    onChange={(e) => setImages(Array.from(e.target.files))}
                  />
                  <span className="text-gray-500">+</span>
                </label>
              </div>
            </div>
            <div className='border rounded-md'>
                <h2 className='border-b py-4 px-8 text-xl'>Pricing*</h2>
            <div className="p-6">
              <label className="block text-gray-700 mb-2">Product Price</label>
              <input
                type="number"
                className="p-3 h-10 w-full border rounded"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                required
              />
            </div>
            <div className="mb-6 px-6">
              <label className="block text-gray-700 mb-2">Discounted Price</label>
              <input
                type="number"
                className="p-3 h-10 w-full border rounded"
                value={discountedPrice}
                onChange={(e) => setDiscountedPrice(e.target.value)}
                required
              />
            </div>
            <div className="mb-6 px-6">
              <label className="block text-gray-700 mb-2">On Sale</label>
              <input
                type="checkbox"
                className="form-checkbox"
                checked={onSale}
                onChange={(e) => setOnSale(e.target.checked)}
              />
            </div>
            <div className="mb-6 px-6 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 mb-2">Discount</label>
                <input
                  type="number"
                  className="p-3 h-10 w-full border rounded"
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value)}
                  placeholder="Discount value"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Type</label>
                <select
                  className="p-3 w-full border rounded"
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="%">%</option>
                  <option value="₹">₹</option>
                </select>
              </div>
            </div>
            </div>
            <div className='border rounded-md mt-5'>
            <h2 className='border-b py-4 px-8 text-xl'>Product Info*</h2>
            <div className="p-6">
              <label className="block text-gray-700 mb-2">Product Name</label>
              <input
                type="text"
                className="p-3 h-10 w-full border rounded"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
            
            <div className="mb-6 px-6">
              <label className="block text-gray-700 mb-2">Description</label>
              <textarea
                className="p-3 w-full border rounded"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="mb-6 px-6">
              <label className="block text-gray-700 mb-2">Brand</label>
              <input
                type="text"
                className="p-3 h-10 w-full border rounded"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
              />
            </div>
            <div className="mb-6 px-6">
              <label className="block text-gray-700 mb-2">Stock</label>
              <input
                type="number"
                className="p-3 h-10 w-full border rounded"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                required
              />
            </div>
            </div>
          </div>
          <div>
            
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Categories</label>
              <div className="grid gap-4">
                {categories.map((category, idx) => (
                  <div key={idx} className="flex items-center">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        value={category}
                        checked={selectedCategories.includes(category)}
                        onChange={(e) => {
                          const { checked, value } = e.target;
                          setSelectedCategories((prev) => 
                            checked ? [...prev, value] : prev.filter((cat) => cat !== value)
                          );
                        }}
                      />
                      <span className="ml-2">{category}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ManageProductOptions options={options} setOptions={setOptions} />
        <button
          className="bg-blue-600 text-white w-44 px-4 py-2 rounded"
          type="submit"
        >
          {isEdit ? 'Update Product' : 'Add Product'}
        </button> 
      </form>
    </div>
  );
};

export default AddProductPage;